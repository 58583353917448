import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { increment } from '../actions/counterActions';

function HomePage() {
  const count = useSelector(state => state.counter.count);
  const dispatch = useDispatch();

  return (
    <div>
      <h1>Welcome to Gracet</h1>
      <p>Gracet React Website</p>
      <p>Counter: {count}</p>
      <button onClick={() => dispatch(increment())}>Increment</button>
    </div>
  );
}

export default HomePage;
